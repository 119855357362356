import type { Ref } from 'vue';

export default (
  visible: Ref<boolean>,
  onUpdateVisible: (value: boolean) => void,
) => {
  const key = getRandomValue().toString();

  const modalStore = useModalStore();

  const modalVisible = computed(() => !!modalStore.modalKeys[key]);

  const onToggleVisible = (value: boolean) => {
    if (value) modalStore.openModal(key);
    else modalStore.closeModal(key);
    onUpdateVisible(value);
  };

  watch(
    visible,
    () => {
      if (visible.value) modalStore.openModal(key);
      else modalStore.closeModal(key);
    },
    { immediate: true },
  );

  return [modalVisible, onToggleVisible] as const;
};
